<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Booking
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="booking-form">
          <v-select
            label="Customer"
            v-model="fields.customer_id"
            :items="customers"
            item-value="id"
            item-text="full_name"
            outlined
            background-color="white"
          >
            <template v-slot:append-outer class="mt-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    depressed
                    color="accent"
                    v-on="on"
                    icon
                    @click="$refs.customerDialog.open()"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New Customer</span>
              </v-tooltip>
            </template>
          </v-select>
          <v-text-field
            label="Date"
            type="date"
            v-model="fields.date"
            outlined
            background-color="white"
          ></v-text-field>
          <div>Available Times</div>
          <v-chip-group v-model="fields.time" active-class="blue--text" column>
            <v-chip value="08:45">08:45</v-chip>
            <v-chip value="10:30">10:30</v-chip>
            <v-chip value="13:00">13:00</v-chip>
            <v-chip value="16:45">16:45</v-chip>
            <v-chip value="20:00">20:00</v-chip>
            <v-chip value="20:15">20:15</v-chip>
            <v-chip value="20:30">20:30</v-chip>
            <v-chip value="21:00">21:00</v-chip>
          </v-chip-group>
          <v-switch label="Manually Enter Times" inset color="green"></v-switch>
          <v-divider class="mb-4"></v-divider>
          <div class="mb-4">Attendees</div>
          <v-text-field
            label="Total Adults"
            outlined
            background-color="white"
            type="number"
            min="0"
          ></v-text-field>
          <v-text-field
            label="Total Babies"
            outlined
            background-color="white"
            type="number"
            min="0"
          ></v-text-field>
          <v-text-field
            label="Total Kids"
            outlined
            background-color="white"
            type="number"
            min="0"
          ></v-text-field>
          <v-text-field
            label="Total Infants"
            outlined
            background-color="white"
            type="number"
            min="0"
          ></v-text-field>
          <v-divider class="mb-4"></v-divider>
          <div class="mb-4">Costing</div>
          <v-text-field
            label="Sub Total *"
            type="number"
            min="0.00"
            step="1.00"
            prefix="£"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="VAT Total *"
            type="number"
            min="0.00"
            step="1.00"
            prefix="£"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="Total *"
            type="number"
            min="0.00"
            step="1.00"
            prefix="£"
            outlined
            background-color="white"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="customer-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
    <customer-dialog ref="customerDialog" />
  </v-dialog>
</template>

<script>
import CustomerDialog from "@/modules/arnprior/views/customers/components/CustomerDialog";

export default {
  components: {
    CustomerDialog,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      booking: {},
      errors: {},
      fields: {
        customer_id: null,
        date: "2022-08-30",
        time: null,
      },
    };
  },

  computed: {
    customers() {
      return this.$store.getters["arnprior/customersStore/all"];
    },
  },

  methods: {
    open(booking = null) {
      if (booking !== null) {
        this.booking = booking;
        this.isEditing = true;
      }
      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.bookingId = this.booking.id;
      }

      this.$store
        .dispatch("arnprior/bookingsStore/saveBooking", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.booking = {};
      this.fields.customer_id = null;
      this.fields.date = "2022-08-30";
      this.fields.time = null;
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>

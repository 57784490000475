<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn
          small
          depressed
          :to="{ name: 'module-arnprior-partypod-arnprior-party-bookings' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Bookings</v-btn
        >
      </v-row>
      <v-row align="center">
        <v-col class="pr-12">
          <h1>
            {{ pluralString(booking.order.customer.full_name) }} Party Pod
            Booking
          </h1>
        </v-col>
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                @click="$refs.bookingDialog.open(booking)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit Booking</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8"> </v-col>
      </v-row>
    </v-container>
    <booking-dialog ref="bookingDialog" />
  </div>
</template>

<script>
import BookingDialog from "./components/BookingDialog";

export default {
  components: {
    BookingDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Glamping Pods",
          disabled: true,
        },
        {
          text: "Bookings",
          disabled: false,
          to: { name: "module-arnprior-pods-arnprior-pod-bookings" },
          exact: true,
        },
      ],
    };
  },

  computed: {
    booking() {
      return this.$store.getters["arnprior/bookingsStore/get"];
    },
  },

  created() {
    this.breadcrumbs.push({
      text: this.booking.order.customer.full_name,
      disabled: true,
    });
  },
};
</script>
